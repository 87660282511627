import unhead_KgADcZ0jPj from "/home/ploi/front.dadoun.co/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/ploi/front.dadoun.co/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/ploi/front.dadoun.co/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/ploi/front.dadoun.co/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/ploi/front.dadoun.co/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/ploi/front.dadoun.co/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/ploi/front.dadoun.co/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/ploi/front.dadoun.co/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/ploi/front.dadoun.co/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_vfCt6Q18Tc from "/home/ploi/front.dadoun.co/node_modules/nuxt-auth-sanctum/dist/runtime/plugin.js";
import primevue_plugin_egKpok8Auk from "/home/ploi/front.dadoun.co/.nuxt/primevue-plugin.mjs";
import plugin_client_XxbS7tGyhp from "/home/ploi/front.dadoun.co/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import plugin_client_OVoKJro5pc from "/home/ploi/front.dadoun.co/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_qyKgRzTu0u from "/home/ploi/front.dadoun.co/modules/nuxt-permissions/src/runtime/plugin.ts";
import plugin_HVXxfS1SwW from "/home/ploi/front.dadoun.co/node_modules/nuxt-multi-tenancy/dist/runtime/plugin.mjs";
import plugin_AUP22rrBAc from "/home/ploi/front.dadoun.co/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import eventBus_P6CbRuQa3W from "/home/ploi/front.dadoun.co/plugins/eventBus.ts";
import laravel_echo_client_xHFhfajl0H from "/home/ploi/front.dadoun.co/plugins/laravel-echo.client.ts";
export default [
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_vfCt6Q18Tc,
  primevue_plugin_egKpok8Auk,
  plugin_client_XxbS7tGyhp,
  plugin_client_OVoKJro5pc,
  plugin_qyKgRzTu0u,
  plugin_HVXxfS1SwW,
  plugin_AUP22rrBAc,
  eventBus_P6CbRuQa3W,
  laravel_echo_client_xHFhfajl0H
]