import validate from "/home/ploi/front.dadoun.co/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/home/ploi/front.dadoun.co/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  permissions: () => import("/home/ploi/front.dadoun.co/middleware/permissions.ts"),
  "sanctum:auth": () => import("/home/ploi/front.dadoun.co/node_modules/nuxt-auth-sanctum/dist/runtime/middleware/sanctum.auth.js"),
  "sanctum:guest": () => import("/home/ploi/front.dadoun.co/node_modules/nuxt-auth-sanctum/dist/runtime/middleware/sanctum.guest.js")
}